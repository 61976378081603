<template>
  <div class="prod-container">
    <filter-search
      :modelList="modelList"
      :brandList="brandList"
      @change="handleFilterChange"
      @add="handleAddSpec"
      @search="handleSearchType"
    ></filter-search>
    <list
      :list="specList"
      @edit="handleEditSpec"
      @succ="getSpeAttrcList"
    ></list>

    <edit-spec
      :show="show"
      :type="type"
      :item="{
        modelId,
        brandId,
        prodId,
        typeId,
      }"
      :prodList="prodList"
      :specId="specId"
      @succ="handleComfirmSucc"
    ></edit-spec>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterSearch from "./components/FilterSearch";
import List from "./components/List";
import EditSpec from "../components/edit-prod/EditSpec";
export default {
  name: "SpecList",
  components: { FilterSearch, List, EditSpec },
  props: {},
  data() {
    return {
      show: false,
      type: "add",
      specList: [],
      modelId: null,
      brandId: null,
      brandIds: [],
      prodId: null,
      typeId: null,
      specId: null,
      prodList: [],
      imageSrc: "",
      allspecs: [],
    };
  },
  computed: {
    ...mapState(["modelList", "brandList"]),
  },
  created() {
    this.handleInit();
  },
  mounted() {},
  methods: {
    //初始化
    async handleInit() {
      await this.$store.dispatch("getGoodsModels");
      await this.$store.dispatch("getAllBrandList");
    },
    //获取规格列表
    async getSpeAttrcList() {
      this.$tool.debounce(100).then(async () => {
        let data = {
          model_id: this.modelId,
        };
        if (this.brandIds) {
          data.listfield_filter = JSON.stringify({ brand_id: this.brandIds });
        }
        if (this.prodId) {
          data.goods_id = this.prodId;
        }
        const res = await this.$store.dispatch("getSpeAttrcList", data);
        this.specList = res.specs.map((item) => {
          const suppliers = item.suppliers.map((item, index) => {
            if (item.priority === 1) {
              const specAttrs = Object.values(item.attrs.sides)[0].attrs;
              return {
                sup_id: item.sup_id,
                sup_name: item.name,
                thickness: item.attrs.extras.thickness.value,
                figureImage: item.attrs.extras.figureImage.value
                  ? JSON.parse(item.attrs.extras.figureImage.value)
                  : [],
                width: specAttrs.width.value,
                height: specAttrs.height.value,
                exportWidth: specAttrs.exportWidth.value,
                exportHeight: specAttrs.exportHeight.value,
                top: specAttrs.top.value,
                left: specAttrs.left.value,
                design_price: specAttrs.design_price.value,
                caremaimage: specAttrs.caremaimage.value,
                round: specAttrs.ltround.value,
                outof_stock: item.outof_stock,
              };
            }
          });
          return {
            spec_id: item.spec_id,
            prodId: item.goods_id,
            prodName: item.goods_title,
            typeName: item.essentialAttrs[0].value_cn,
            typeId: item.essentialAttrs[0].value_en,
            brandId: item.essentialAttrs[0].parent.brand_id || "",
            brandName: item.essentialAttrs[0].parent.brand_name,
            stuffName: item.essentialAttrs[1].value_cn,
            ...suppliers[0],
          };
        });
        this.allspecs = this.specList;
      });
    },
    //搜索符合的型号
    handleSearchType(val) {
      if (val === "") {
        this.specList = this.allspecs;
        return;
      }
      this.specList = this.allspecs.reduce((prev, cur) => {
        if (cur.typeName.indexOf(val) > -1) {
          prev.push(cur);
        }
        return prev;
      }, []);
    },
    //查看图片
    handleImageShow(src) {
      this.imageShow = !this.imageShow;
      this.imageSrc = src;
    },
    //筛选改变
    async handleFilterChange(e) {
      this.modelId = e.modelId;
      this.brandIds = e.brandIds;
      this.prodId = e.prodId;
      this.prodList = e.prodList;
      this.getSpeAttrcList();
    },
    //新增规格
    handleAddSpec(id) {
      this.show = !this.show;
      this.type = "add";
      this.specId = null;
    },
    //编辑规格
    handleEditSpec(item) {
      // this.dialogTitle = "规格修改";

      this.show = !this.show;
      this.type = "edit";
      this.specId = item.spec_id;
      this.typeId = Number(item.typeId);
      this.brandId = item.brandId;
      this.prodId = item.prodId;
      // this.dialogVisible = true;
      // this.isLiberty = false;
    },
    //页面展示数量改变
    sizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getSpeAttrcList();
    },
    //产品页页码改变事件
    changePage(page) {
      this.page = page;
      this.getSpeAttrcList();
    },
    //提交成功
    handleComfirmSucc() {
      this.dialogVisible = false;
      this.getSpeAttrcList();
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  .el-row {
    display: flex;
    align-items: center;
    .el-dropdown {
      height: 30px;
      line-height: 30px;
      text-align: center;
      width: 100px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
  .el-table {
    margin-top: 20px;
  }
  .specBox {
    /deep/.el-dialog__body {
      padding: 10px 20px;
    }
    /deep/.el-input__inner {
      padding: 0;
    }
  }
  /deep/.el-pagination {
    text-align: left;
  }
  .supBox {
    .supply {
      display: flex;
      align-items: center;
      justify-content: space-around;
      /deep/.el-input__inner {
        width: 100px;
      }
      /deep/.el-input {
        width: 100px;
      }
    }
  }
}
.sele {
  /deep/ .success-row {
    color: #3333ee;
    font-size: 20px;
  }
}
.el-button {
  padding: 0 10px !important;
  height: 28px !important;
}
</style>
