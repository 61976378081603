<template>
  <div class="filterSearch-container">
    <el-row :gutter="20">
      <el-col :span="4">
        <el-select filterable v-model="modelId" placeholder="请选择模型">
          <el-option
            v-for="item in modelList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          filterable
          clearable
          v-model="prodId"
          placeholder="请选择产品"
        >
          <el-option
            v-for="item in prodList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <all-select
          :model="brandIds"
          :all="allBrandId"
          placeholder="品牌选择"
          @change="handleCheckedBrand"
        >
          <el-option
            v-for="item in brandList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </all-select>
      </el-col>

      <el-col :span="6">
        <el-input
          placeholder="请输入型号关键字"
          v-model="searchVal"
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleSearch"
          ></el-button>
        </el-input>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" icon="el-icon-plus" @click="handleAddSpec"
          >新增规格</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>
<script>
import AllSelect from "@/components/AllSelect";
export default {
  name: "FilterSearch",
  props: {
    modelList: Array,
    brandList: Array,
  },
  components: { AllSelect },
  data() {
    return {
      initLock: false, //初始化锁
      searchVal: "",
      modelId: null,
      prodId: null,
      prodList: [],
      brandIds: [],
      allBrandId: [],
    };
  },
  created() {},
  computed: {
    //监听筛选参数
    filterParam() {
      return {
        modelId: this.modelId,
        prodId: this.prodId,
        brandIds: this.brandIds,
        prodList: this.prodList,
      };
    },
  },
  watch: {
    async modelList() {
      this.modelId = this.modelList[0].id;
      this.getModelFindProd();
    },
    filterParam() {
      this.handleFilter();
    },
  },
  methods: {
    //获取产品列表
    async getModelFindProd() {
      this.prodList = await this.$store.dispatch("getModelFindProd", {
        model_id: this.modelId,
      });
      this.prodId = this.prodList[0].id;
      this.brandIds.push(this.prodId);
      this.allBrandId = this.brandList.map((item) => {
        return item.id;
      });
      console.log(this.allBrandId);
    },
    handleCheckedBrand(param) {
      this.brandIds = param;
    },
    //改变筛选
    handleFilter() {
      this.$tool.debounce(100).then(() => {
        const data = {
          modelId: this.modelId,
          prodId: this.prodId,
          brandIds: this.brandIds,
          prodList: this.prodList,
        };
        this.$emit("change", data);
      });
    },
    //新增
    handleAddSpec() {
      this.$emit("add");
    },
    //搜索
    handleSearch() {
      this.$emit("search", this.searchVal);
    },
  },
};
</script>
<style lang="less" scoped>
.filterSearch-container {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
</style>
