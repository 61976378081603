<template>
  <table-container :list="list" height="750">
    <el-table-column
      type="index"
      label="序号"
      fixed
      align="center"
      width="50"
    ></el-table-column>
    <el-table-column prop="brandName" label="品牌" fixed> </el-table-column>
    <el-table-column prop="typeName" label="型号" fixed> </el-table-column>
    <el-table-column prop="prodName" label="产品"> </el-table-column>
    <el-table-column prop="stuffName" label="材质"> </el-table-column>
    <el-table-column prop="exportWidth" label="导出宽度"> </el-table-column>
    <el-table-column prop="exportHeight" label="导出高度"> </el-table-column>
    <el-table-column prop="width" label="手机壳宽度"> </el-table-column>
    <el-table-column prop="height" label="手机壳高度"> </el-table-column>
    <el-table-column prop="top" label="上边距"> </el-table-column>
    <el-table-column prop="left" label="左边距"> </el-table-column>
    <el-table-column prop="thickness" label="厚度"> </el-table-column>
    <el-table-column prop="round" label="圆角"> </el-table-column>
    <el-table-column prop="caremaimage" label="装饰图片">
      <template slot-scope="scope">
        <ky-image
          :images="[
            scope.row.caremaimage ? base_url + scope.row.caremaimage : '',
          ]"
          :src="scope.row.caremaimage ? base_url + scope.row.caremaimage : ''"
        ></ky-image>
      </template>
    </el-table-column>
    <el-table-column prop="figureImage" label="实物图片">
      <template slot-scope="scope">
        <ky-image
          :images="scope.row.figureImage"
          :src="
            scope.row.figureImage.length > 0
              ? base_url + scope.row.figureImage[0]
              : ''
          "
        ></ky-image>
      </template>
    </el-table-column>
    <el-table-column prop="sup_name" label="供应商"> </el-table-column>
    <el-table-column prop="outof_stock" label="缺货状态">
      <template slot-scope="scope">
        <div class="outof_stock" v-if="scope.row.outof_stock === 0">不缺货</div>
        <div class="outof_stock false" v-else>缺货</div>
      </template>
    </el-table-column>
    <!-- <el-table-column prop="date" label="创建时间"> </el-table-column> -->
    <el-table-column prop="name" label="操作" width="250" fixed="right">
      <template slot-scope="scope">
        <el-button type="primary" size="mini" @click="handleEdit(scope.row)"
          >编辑</el-button
        >
        <delete-btn :item="scope.row" @del="handleDelete"></delete-btn>
        <el-button type="warning" size="mini" @click="handleStockout(scope.row)"
          >缺货</el-button
        >
        <el-button
          type="success"
          size="mini"
          @click="handleNoStockout(scope.row)"
          >不缺货</el-button
        >
      </template>
    </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
    modelList: Array,
    brandList: Array,
  },
  methods: {
    //编辑
    handleEdit(item) {
      this.$emit("edit", item);
    },
    //删除
    async handleDelete(item) {
      const res = await this.$store.dispatch("delSpec", {
        spec_id: item.spec_id,
      });
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
      });
    },
    //缺货
    async handleStockout(item) {
      const res = await this.$store.dispatch("postSpecStockout", {
        spec_id: item.spec_id,
        sup_id: item.sup_id,
        outof_stock: 1,
      });
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
      });
    },
    //不缺货
    async handleNoStockout(item) {
      const res = await this.$store.dispatch("postSpecStockout", {
        spec_id: item.spec_id,
        sup_id: item.sup_id,
        outof_stock: 0,
      });
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
      });
    },
    handleImageShow(src) {
      this.$emit("imageShow", src);
    },
  },
};
</script>
<style lang="less" scoped>
.outof_stock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  &.false {
    background-color: red;
    color: #fff;
  }
}
</style>